import React from 'react';
import "./ArrowDirection.scss";
import { HiChevronDown } from "react-icons/hi";

const ArrowDirection = (props) => {

    const scrollToSection = (elementRef) => {
        window.scrollTo({
            top: elementRef.current.offsetTop,
            behavior: 'smooth'
        })
    }

    return (
        <div className="arrow-direction" onClick={() => scrollToSection(props.target)}>
            <HiChevronDown />
        </div>
    )
}

export default ArrowDirection