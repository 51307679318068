import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Col } from 'reactstrap';
import './ProjectCard.scss';

const ProjectCard = (props) => {
  const [hover, setHover] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.replace('#', ''));
      var headerOffset = 20;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <Col md={6} className='mb-4' id={props?.id || ''}>
      <Link to='/project/detail' state={{ props }}>
        <div
          className='project-card'
          style={{
            backgroundImage: `${
              hover
                ? ''
                : 'linear-gradient(rgba(0, 0, 0, 0.427), rgba(0, 0, 0, 0.4)), '
            }url("${props.background}")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            WebkitFilter: hover ? 'none' : 'grayscale(100%)',
            filter: hover ? 'none' : 'grayscale(100%)',
          }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <div className='project-card-info'>
            <h4>{props.title}</h4>
            <p>{props.location}</p>
          </div>
        </div>
      </Link>
    </Col>
  );
};

export default ProjectCard;
