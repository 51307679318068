import React from 'react'
import { Carousel } from '3d-react-carousal';

const Service = () => {
    let slides = [
        <img src='/pictures/interior/office-tangerang/office1.jpg' alt="" />,
        <img src='/pictures/interior/mr-a-private-house-surabaya/private-house1.jpg' alt="" />,
        <img src='/pictures/interior/mr-t-private-house-surabaya/interior-photos/private-house1.jpg' alt="" />,
    ];

    return (
        <Carousel slides={slides} autoplay={false} interval={1000} />
    )
}

export default Service