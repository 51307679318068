import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { HiChevronDoubleLeft } from "react-icons/hi";
import "./ProjectSidebar.scss";

const ProjectSidebar = () => {
    const [interiorActive, setInteriorActive] = useState('');
    const [architectureActive, setArchitectureActive] = useState('');
    const navigate = useNavigate();

    const scrollToSection = (e) => {
        navigate('/project#' + e);
        window.scrollTo({
            top: document.getElementById(e).offsetTop,
            behavior: 'smooth'
        })

        if (e === 'interior') {
            setInteriorActive('active');
            setArchitectureActive('');
        }
        if (e === 'architecture') {
            setInteriorActive('');
            setArchitectureActive('active');
        }
    };

    const location = useLocation();



    return (
        <aside className="project-sidebar">
            <div className="sqm-logo py-3">
                <Link to="/">
                    <img src="/pictures/logo-2.png" alt="logo" />
                </Link>


            </div>
          
            {location.pathname === "/project/detail" ? (
                <div>
                    <Link to="/project" className='back-icon'><HiChevronDoubleLeft style={{ width: 30, height: 30, color: '#e6b10e', margin: '0 45%' }} /></Link>
                </div>
            ) : (
                <Link to="/" className='back-icon'><HiChevronDoubleLeft style={{ width: 30, height: 30, color: '#e6b10e', margin: '0 45%' }} /></Link>
            )}

            <nav className="nav">
                <ul>

                    <li><Link to="/project#interior" onClick={() => scrollToSection('interior')} className={interiorActive}>Interior</Link></li>
                    <li><Link to="/project#architecture" onClick={() => scrollToSection('architecture')} className={architectureActive}>Architecture</Link></li>
                </ul>

            </nav>
        </aside>
    )
}

export default ProjectSidebar