import Layout from '../components/Layout';
import Header from './Header';
import { Col, Container, Row } from 'reactstrap';
import './Homepage.scss';
import { FormControl, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import FloatWhatsapp from '../components/FloatWhatsapp';
import FloatModal from '../components/FloatModal';
import { BsInstagram } from 'react-icons/bs';
import { FaBehanceSquare } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';
import Button from '@mui/material/Button';

export default function ContactUs() {
  return (
    <div>
      <Header></Header>
      {/* <div
        style={{
          position: 'fixed',
          left: '10px',
          transform: 'translateY(50%)',
          width: 'max-content',
          height: '100%',
        }}
      >
        Halo
      </div> */}
      <Container style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            width: 'max-content',
            height: '100%',
            top: '50%',
            left: '-15%',
          }}
        >
          <div
            style={{
              background: '#3c3634',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              fontSize: '1.5rem',
            }}
          >
            <a
              href='https://www.instagram.com/sqm.studio/'
              target='_blank'
              rel='noreferrer'
              style={{
                margin: '0 1rem',
                padding: '0.5rem 0 0.5rem 0',
                color: '#e6b10e',
              }}
            >
              <BsInstagram />
            </a>
            <a
              href='https://www.behance.net/sqmstudio/'
              target='_blank'
              rel='noreferrer'
              style={{
                margin: '0 1rem',
                padding: '0.5rem 0 0.5rem 0',
                color: '#e6b10e',
              }}
            >
              <FaBehanceSquare />
            </a>
            <a
              href='mailto:sqmstudio2021@gmail.com'
              target='_blank'
              rel='noreferrer'
              style={{
                margin: '0 1rem',
                padding: '0.5rem 0 0.5rem 0',
                color: '#e6b10e',
              }}
            >
              <AiOutlineMail />
            </a>
          </div>
        </div>
        <div style={{ paddingTop: '12rem' }}>
          <h3 style={{ textAlign: 'center' }}>
            Ready to start the journey towards your dream design? Fill in the
            form below or contact us directly via Whatsapp +62823-6100-8195
          </h3>
          <form style={{ padding: '3rem' }}>
            <FormControl fullWidth sx={{ marginBottom: '2rem' }}>
              <TextField
                type='text'
                id='outlined-basic'
                variant='standard'
                label='Name (Required)'
                size='small'
                fullWidth
                required
                sx={{ outlineColor: 'white' }}
                // color='primary'
              />
            </FormControl>
            <FormControl fullWidth sx={{ marginBottom: '2rem' }}>
              <TextField
                type='text'
                id='outlined-basic'
                variant='standard'
                label='Email (Required)'
                size='small'
                fullWidth
                required
              />
            </FormControl>
            <FormControl fullWidth sx={{ marginBottom: '2rem' }}>
              <TextField
                type='text'
                id='outlined-basic'
                variant='standard'
                label='Phone (Required)'
                size='small'
                fullWidth
                required
              />
            </FormControl>
            <FormControl fullWidth sx={{ marginBottom: '2rem' }}>
              <TextField
                type='text'
                id='outlined-multiline-flexible'
                // variant='standard'
                label='Message'
                size='small'
                fullWidth
                multiline
                rows={5}
              />
            </FormControl>
            <Button
              style={{
                backgroundColor: '#e6b10e',
                color: 'white',
                borderRadius: '10px',
                fontWeight: 550,
                fontSize: '0.9rem',
              }}
              type='submit'
              sx={{ textTransform: 'uppercase' }}
            >
              Get Us Commited
            </Button>
            {/* <Button
              outline
              style={{
                outlineColor: 'black',
                background: 'transparent',
                color: 'black',
              }}
            >
              GET US COMMITED
            </Button> */}
          </form>
        </div>
      </Container>
      <div className='footer py-5'>
        <Container>
          <Row>
            <Col md={6} className='mt-5'>
              <small className='copyright'>Copyright - 2022 SQM Studio</small>
            </Col>
            <Col md={6}>
              <div className='text-md-end'>
                <h5>Follow Us At</h5>
              </div>
              <div className='sosmed text-md-end'>
                <a
                  href='https://www.instagram.com/sqm.studio/'
                  target='_blank'
                  rel='noreferrer'
                  style={{ margin: '0 1rem' }}
                >
                  <BsInstagram />
                </a>
                <a
                  href='https://www.behance.net/sqmstudio/'
                  target='_blank'
                  rel='noreferrer'
                  style={{ margin: '0 1rem' }}
                >
                  <FaBehanceSquare />
                </a>
              </div>
              <div className='contact text-md-end my-3'>
                For more information:{' '}
                <a href='/contact' target='_blank'>
                  Hubungi SQM
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='location py-5'>
        <Container>
          <Row>
            <Col md={12} className='mb-2'>
              <h5 className='location-title'>OUR LOCATIONS</h5>
            </Col>
            <Col md={2} sm={3} xs={3}>
              <Link to='/project#surabaya'>Surabaya</Link>
            </Col>
            <Col md={2} sm={3} xs={3}>
              <Link to='/project#solo'>Solo</Link>
            </Col>
            <Col md={2} sm={3} xs={3}>
              <Link to='/project#tangerang'>Tangerang</Link>
            </Col>
            <Col md={2} sm={3} xs={3}>
              <Link to='/project#semarang'>Semarang</Link>
            </Col>
            <Col md={2} sm={3} xs={3}>
              <Link to='/project#sidoarjo'>Sidoarjo</Link>
            </Col>
          </Row>
        </Container>
      </div>
      <FloatModal />
      <FloatWhatsapp />
    </div>
  );
}
