import React from 'react';
import "./BrokenPage.scss";

export default function BrokenPage() {
  return (

    <div className='broken-page'>
      <div className='broken-page-content'>
        <h1>
          Your screen resolution is too small.
        </h1>
        <p>
          This page is not supported for mobile or tablet view. please try to
          open it using desktop or laptop
        </p>
      </div>
    </div>
    
  )
}
