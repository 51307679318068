import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import "./FloatModal.scss";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { AiOutlineMail } from 'react-icons/ai'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
        border: theme.spacing(0),
    },
}));

const valueRequest = [
    {
        value: 'Architecture',
        label: 'Architecture',
    },
    {
        value: 'Interior',
        label: 'Interior',
    },
];

const valueProjectType = [
    {
        value: 'Apartement',
        label: 'Apartement',
    },
    {
        value: 'Residential',
        label: 'Residential',
    },
    {
        value: 'Commercial',
        label: 'Commercial',
    },
];

const valueBudget = [
    {
        value: '<100jt',
        label: '<100jt',
    },
    {
        value: '100jt - 250jt',
        label: '100jt - 250jt',
    },
    {
        value: '250jt - 500jt',
        label: '250jt - 500jt',
    },
    {
        value: '500jt - 1M',
        label: '500jt - 1M',
    },
    {
        value: '>1M',
        label: '>1M',
    },
];

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, border: 0 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function FloatModal() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [selectRequest, setSelectRequest] = useState('');
    const [selectProjectType, setselectProjectType] = useState('');
    const [selectBudget, setSelectBudget] = useState('');

    const resetValue = () => {
        setName('');
        setEmail('');
        setPhone('');
        setSelectRequest('');
        setselectProjectType('');
        setSelectBudget('');
    }

    const handleSave = (e) => {
        e.preventDefault();
        const objData = {
            name,
            email,
            phone,
            selectRequest,
            selectProjectType,
            selectBudget,
        }
        alert(JSON.stringify(objData));
        resetValue();
    }

    return (
        <div>
            <Button variant='contained' style={{ position: "fixed", width: 60, height: 60, bottom: "2vw", right: "0.5vw", color: "grey", borderRadius: "50px", textAlign: "center", fontSize: "30px", zIndex: 100, backgroundColor: "white" }} onClick={handleClickOpen}>
                <AiOutlineMail />
            </Button>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="xs"
                style={{ bottom: '-15vw', right: "-73vw" }}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>Form Request</BootstrapDialogTitle>

                <form onSubmit={handleSave} id="inicoy">
                    <DialogContent dividers>
                        <FormControl fullWidth style={{ marginBottom: "1.5vw" }}>
                            <TextField
                                type="text"
                                id="outlined-basic"
                                variant="standard"
                                label="Name"
                                size='small'
                                fullWidth
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            >

                            </TextField>
                        </FormControl>
                        <FormControl fullWidth style={{ marginBottom: "1.5vw" }}>

                            <TextField
                                type="text"
                                id="outlined-basic"
                                variant="standard"
                                label="Email"
                                size='small'
                                fullWidth
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            >

                            </TextField>
                        </FormControl>
                        <FormControl fullWidth style={{ marginBottom: "1.5vw" }}>

                            <TextField
                                type="number"
                                id="outlined-basic"
                                variant="standard"
                                label="phone"
                                size='small'
                                fullWidth
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                required
                            >
                            </TextField>
                        </FormControl>
                        <div style={{ marginBottom: "1.5vw" }}>
                            <TextField
                                id="outlined-basic"
                                variant="standard"
                                label="Request"
                                size='small'
                                style={{ width: "45%", marginRight: "40px" }}
                                value={selectRequest}
                                onChange={(e) => setSelectRequest(e.target.value)}
                                required
                                select

                            >

                                {valueRequest.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}

                            </TextField>
                            <TextField
                                id="outlined-basic"
                                variant="standard"
                                label="Project Type"
                                size='small'
                                style={{ width: "45%" }}
                                value={selectProjectType}
                                onChange={(e) => setselectProjectType(e.target.value)}
                                required
                                select
                            >
                                {valueProjectType.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <FormControl fullWidth style={{ marginBottom: "1.5vw" }}>
                            <TextField
                                id="outlined-basic"
                                variant="standard"
                                label="Budget"
                                size='small'
                                fullWidth
                                value={selectBudget}
                                onChange={(e) => setSelectBudget(e.target.value)}
                                required
                                select
                            >
                                {valueBudget.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                        <Button style={{ backgroundColor: '#e6b10e', color: "white", borderRadius: "10px", fontWeight: 550, margin: "0.5vw", marginTop: "3vw", left: "17vw" }} form='inicoy' type='submit'>
                            Send
                        </Button>
                    </DialogContent>
                </form>
            </BootstrapDialog>
        </div>
    );
}