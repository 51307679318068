import React from 'react';
import "./TextStyle.scss";

const TextStyle = (props) => {

    return (
        <span className={`${props.type}`}>
            {props.text}
        </span>
    )
}

export default TextStyle