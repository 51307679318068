import React, { useEffect, useState } from 'react';
import './App.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './pages/Homepage';
import Project from './pages/Project';
import ProjectDetailCard from './components/Reusable/ProjectDetailCard';
import Lottie from 'react-lottie';
import animationData from './animations/loading.json';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';

import Layout from './components/Layout';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  // useEffect(() => {
  //     const handleLoading = () => {
  //         setIsLoading(false);
  //     }

  //     window.addEventListener("load", handleLoading);
  //     return () => window.removeEventListener("load", handleLoading);
  // }, [])

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className='screen-loading'>
          <Lottie
            options={defaultOptions}
            height={300}
            width={300}
            isClickToPauseDisabled={true}
          />
        </div>
      ) : (
        <Router>
          <Routes>
            <Route path='/' element={<Homepage />} />
            <Route path='/project' element={<Project />} />
            <Route path='/project/detail' element={<ProjectDetailCard />} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/contact' element={<ContactUs />} />
          </Routes>
        </Router>
      )}
    </>
  );
}

export default App;
