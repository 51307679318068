import React, { useEffect, useRef, useState } from 'react';
import {
  NavLink as RRNavLink,
  Link,
  useNavigate,
  Route,
} from 'react-router-dom';
import {
  Row,
  Col,
  Container,
  Button,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';

import './Homepage.scss';
import 'swiper/css';
import { Pannellum } from 'pannellum-react';
import Carousel from '../components/Reusable/Carousel';
import Service from '../components/Service';
import {
  CarouselData,
  RecentProject,
  carouselDataV2,
} from '../components/Reusable/CarouselData';
import FloatWhatsapp from '../components/FloatWhatsapp';
import './Homepage.scss';
import { BsInstagram } from 'react-icons/bs';
import { FaBehanceSquare } from 'react-icons/fa';
import ArrowDirection from '../components/Reusable/ArrowDirection';
import TextStyle from '../components/Reusable/TextStyle';
import Header from './Header';
import Layout from '../components/Layout';
import FloatModal from '../components/FloatModal';

const Homepage = () => {
  const recent = useRef(null);
  const about = useRef(null);
  const news = useRef(null);
  const service = useRef(null);
  const navigate = useNavigate();

  const [surabayaActive, setSurabayaActive] = useState('');
  const [soloActive, setSoloActive] = useState('');
  const [banyuwangiActive, setBanyuwangiActive] = useState('');
  const [tangerangActive, setTangerangActive] = useState('');
  const [semarangActive, setSemarangActive] = useState('');
  const [sidoarjoActive, setSidoarjoActive] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Header />
      {/* <Carousel slides={CarouselData} autoSlide={true} /> */}
      <div>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          modules={[Autoplay]}
          autoplay={{ delay: 5500 }}
        >
          {carouselDataV2.map((el, i) => {
            return (
              <SwiperSlide key={i} style={{ height: ' 100vh' }}>
                <img
                  src={el.image}
                  alt={el.title}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    filter: 'brightness(75%)',
                  }}
                />
                <div
                  className='slider-description'
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Container>
                    <Row>
                      <Col md={12}>
                        <div
                          className='mb-4 mx-md-5 mx-3'
                          style={{
                            background: 'rgba(255,255,255,0.4)',
                            padding: '30px',
                            display: 'flex',
                            justifyItems: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <h3 className='mx-md-5 mx-3'>
                            <TextStyle
                              text={el.title}
                              type='tracking-in-expand'
                            />
                          </h3>
                          {el.button === true ? (
                            <Link to={'/contact'}>
                              <Button
                                style={{
                                  width: 'max-content',
                                  color: ' white',
                                }}
                                color='warning'
                                outline
                                size='lg'
                              >
                                <h5>Click Here</h5>
                              </Button>
                            </Link>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <ArrowDirection target={recent} />

      <div className='recent-project pt-5' ref={recent}>
        <Container>
          <Row>
            <Col md={12}>
              <div className='mb-5 mx-md-0 mx-3'>
                <h5 className='recent-title'>Recent Projects</h5>
                <div
                  className='mt-4'
                  style={{ position: 'absolute', left: '0' }}
                >
                  <Carousel
                    slides={RecentProject}
                    autoSlide={false}
                    direction={true}
                    count={true}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <ArrowDirection target={about} />
      </div>

      <div className='about pt-4' ref={about}>
        <Container>
          <Row>
            <Col md={12}>
              <div className='mb-5 mx-md-0 mx-3'>
                <h5 className='about-title'>Interior Design 1</h5>
                <div className='about-description mt-4'>
                  <h5 style={{ textAlign: 'center' }}>
                    <span>
                      Creating a Work of Design According To Function And
                      Aesthetic Form
                    </span>
                  </h5>
                  <p
                    className='mx-5 my-3 px-md-5 px-0'
                    style={{
                      fontSize: 'calc(8px + 1vw)',
                      textAlign: 'justify',
                    }}
                  >
                    SQM STUDIO's distinctive interior and architectural design
                    presents a ‘Tropical' concept that combines elements of wood
                    and the green of the natural surroundings.In making interior
                    or architectural designs, we always analyze the needs and
                    problems that exist in existing conditions and provide
                    custom design solutions for each project we handle, then
                    integrate the interior shape with the overall building
                    shape. Our goal is to create interior or architectural
                    designs that are intimate or comfortable, warm, and
                    welcoming to the residents.
                  </p>
                  <Pannellum
                    width='100%'
                    height='500px'
                    image='/pictures/360/1.jpg'
                    pitch={10}
                    yaw={180}
                    hfov={110}
                    autoLoad
                    // onLoad={() => {
                    //     console.log("panorama loaded");
                    // }}
                  />
                  <p
                    className='mx-5 my-3 px-md-5 px-0'
                    style={{
                      fontSize: 'calc(8px + 1vw)',
                      textAlign: 'justify',
                    }}
                  >
                    For this luxury private residence, we present an interior
                    that seems luxurious but still warm through the play of
                    black marble, wood elements, and some gold materials as
                    elegant interior accents. With simple decorations and a
                    combination of landscape on the exterior as a supporting
                    background, an interior design is formed that is able to
                    give a light, elegant and spacious tropical impression
                    throughout the house.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className='mb-5 mx-md-0 mx-3'>
                {/* <h5 className='about-title'>Interior Design 2</h5> */}
                <div className='about-description mt-4'>
                  {/* <p>Leading the hotel design industry since 1965</p>
                  <h5>
                    HBA is <span>the birth place of hospitality design</span>{' '}
                    <br />
                    and determines the future of hotels globally
                  </h5> */}
                  <Pannellum
                    width='100%'
                    height='500px'
                    image='/pictures/360/2.jpg'
                    pitch={10}
                    yaw={10}
                    hfov={110}
                    autoLoad
                    // onLoad={() => {
                    //     console.log("panorama loaded");
                    // }}
                  />
                  <p
                    className='mx-5 my-3 px-md-5 px-0'
                    style={{
                      fontSize: 'calc(8px + 1vw)',
                      textAlign: 'justify',
                    }}
                  >
                    An interior design work with a modern Japanese concept,
                    presenting a simple and practical design with cool white
                    dominant shades and warmth from several wood materials. It
                    creates a calming atmosphere for the residents.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <ArrowDirection target={service} />
      </div>

      {/* <div className="news" ref={news}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="mb-5 mx-md-0 mx-3">
                                <h5 className="news-title">News</h5>
                                <small>World-renowned as the</small>
                                <h4>Number 1 Hospitality Design Firm</h4>
                                <div className="more mt-5"><a href="/news" target="_blank">View News &amp; Recognition</a></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <ArrowDirection target={service} />
            </div> */}

      <div className='service py-5' ref={service}>
        <Container>
          <Row>
            <Col md={12}>
              <div className='mt-2 mb-5'>
                <h5 className='service-title'>Our Portofolio</h5>
              </div>
            </Col>
          </Row>
        </Container>
        <Service />
      </div>

      <div className='footer py-5'>
        <Container>
          <Row>
            <Col md={6} className='mt-5'>
              <small className='copyright'>Copyright - 2022 SQM Studio</small>
            </Col>
            <Col md={6}>
              <div className='text-md-end'>
                <h5>Follow Us At</h5>
              </div>
              <div className='sosmed text-md-end'>
                <a
                  href='https://www.instagram.com/sqm.studio/'
                  target='_blank'
                  rel='noreferrer'
                  style={{ margin: '0 1rem' }}
                >
                  <BsInstagram />
                </a>
                <a
                  href='https://www.behance.net/sqmstudio/'
                  target='_blank'
                  rel='noreferrer'
                  style={{ margin: '0 1rem' }}
                >
                  <FaBehanceSquare />
                </a>
              </div>
              <div className='contact text-md-end my-3'>
                For more information:{' '}
                <a href='/contact' target='_blank'>
                  Hubungi SQM
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='location py-5'>
        <Container>
          <Row>
            <Col md={12} className='mb-2'>
              <h5 className='location-title'>OUR LOCATIONS</h5>
            </Col>
            <Col md={2} sm={3} xs={3}>
              <Link to='/project#surabaya'>Surabaya</Link>
            </Col>
            <Col md={2} sm={3} xs={3}>
              <Link to='/project#solo'>Solo</Link>
            </Col>
            <Col md={2} sm={3} xs={3}>
              <Link to='/project#tangerang'>Tangerang</Link>
            </Col>
            <Col md={2} sm={3} xs={3}>
              <Link to='/project#semarang'>Semarang</Link>
            </Col>
            <Col md={2} sm={3} xs={3}>
              <Link to='/project#sidoarjo'>Sidoarjo</Link>
            </Col>
          </Row>
        </Container>
      </div>
      <FloatModal />
      <FloatWhatsapp />
    </Layout>
  );
};

export default Homepage;
