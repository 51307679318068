import BrokenPage from './BrokenPage';
import "./Layout.scss";



const Layout = ({ children }) => {
  return (
    <div>
      <BrokenPage />
      <main className='layout'>
        <div>{children}</div>
      </main>
    </div>
  );
};

export default Layout;
