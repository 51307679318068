import React, { useState } from 'react';
import { Row, Col, Container, Button } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './ProjectDetailCard.scss';
import { ImagerDisplay, ImagerImg } from '../../imager/index.js';
import ProjectSidebar from './ProjectSidebar';
import FloatWhatsapp from '../FloatWhatsapp';
import FloatModal from '../FloatModal';

const ProjectDetailCard = () => {
  const location = useLocation();

  const [sliders, setSliders] = useState(location.state.props.slideItems);

  const responsive = {
    0: {
      items: 3,
    },
    600: {
      items: 3,
    },
    1024: {
      items: 3,
    },
  };

  let contents = [];

  const handleClick = (value) => {
    if (value === 'progress') {
      setSliders(location.state.props.progressPhotos);
    } else if (value === 'real') {
      setSliders(location.state.props.realPhotos);
    } else {
      setSliders(location.state.props.slideItems);
    }
  };

  sliders.map((item, i) => {
    const style = { height: 'calc(15rem + 2.5vw)', paddingRight: '0.5vw' };
    return (contents[i] = (
      <div className='item-img' style={style}>
        <ImagerImg src={item} alt='' />
      </div>
    ));
  });

  return (
    <>
      <ProjectSidebar />

      <section className='project-content-detail'>
        <Container>
          <div className='mt-5'>
            <ImagerDisplay z-index='2000' />
            <AliceCarousel
              responsive={responsive}
              disableDotsControls
              // disableButtonsControls
              items={contents}
            ></AliceCarousel>
          </div>

          <div className='project-content-detail-info mt-4'>
            <Row>
              <Col md={12}>
                <h5 className='info-title'>{location.state.props.title}</h5>
                {location.state.props.progressPhotos && (
                  <div style={{ position: 'absolute', top: '0', right: '0' }}>
                    <Button
                      className='btn-slider mx-2'
                      onClick={() => handleClick('interior')}
                    >
                      3D Rendering
                    </Button>
                    <Button
                      className='btn-slider mx-2'
                      onClick={() => handleClick('progress')}
                    >
                      Progress Photos
                    </Button>
                    <Button
                      className='btn-slider mx-2'
                      onClick={() => handleClick('real')}
                    >
                      Real Photos
                    </Button>
                  </div>
                )}
              </Col>
              <Col md={12}>
                <p className='info-location'>{location.state.props.location}</p>
                <p className='info-year'>
                  {location.state.props.year} | {location.state.props.type}
                </p>
                <p className='info-description'>
                  {location.state.props.description}
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <FloatModal />
      <FloatWhatsapp />
    </>
  );
};

export default ProjectDetailCard;
