import Layout from '../components/Layout';
import Header from './Header';
import { Col, Container, Row } from 'reactstrap';
import './Homepage.scss';
import { BsInstagram } from 'react-icons/bs';
import { FaBehanceSquare } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import FloatWhatsapp from '../components/FloatWhatsapp';
import FloatModal from '../components/FloatModal';

export default function AboutUs() {
  return (
    <Layout>
      <Header></Header>
      <Container style={{ paddingBottom: '3rem' }}>
        <div style={{ paddingTop: '12rem' }}>
          <h1 className='mx-5 my-3 px-md-5 px-0' style={{ fontSize: '4rem' }}>
            About Us
          </h1>
          <Row>
            <Col md={12}>
              <p
                className='mx-5 my-3 px-md-5 px-0'
                style={{
                  fontSize: 'calc(8px + 1vw)',
                  textAlign: 'justify',
                }}
              >
                Founded in 2021, SQM Studio is a tropical architecture &
                interior design online studio, founded by an architect and an
                interior designer who are passionate about what we do. We
                specialize in residential & commercial projects.
              </p>
            </Col>
            <Col md={12}>
              <h1 style={{ width: '100%', textAlign: 'center' }}>
                Company Value
              </h1>
            </Col>
            <Col md={12}>
              <h1
                className='mx-5 my-3 px-md-5 px-0'
                style={{ width: '100%', textAlign: 'start' }}
              >
                Design is priority
              </h1>
            </Col>
            <Col md={12}>
              <p
                className='mx-5 my-3 px-md-5 px-0'
                style={{
                  fontSize: 'calc(8px + 1vw)',
                  textAlign: 'justify',
                }}
              >
                Time and cost efficiency are important points in the
                implementation of design work for the owner and designer; With
                an online studio system, we hope that the architectural &
                interior works we design are able to provide solutions for your
                needs. We always consider ergonomics, aesthetics, the form and
                function of a room/building in every design we create.
              </p>
              <Col md={12}>
                <h1
                  className='mx-5 my-3 px-md-5 px-0'
                  style={{ width: '100%', textAlign: 'start' }}
                >
                  Customer satisfaction
                </h1>
              </Col>

              <p
                className='mx-5 my-3 px-md-5 px-0'
                style={{
                  fontSize: 'calc(8px + 1vw)',
                  textAlign: 'justify',
                }}
              >
                We strive to provide exceptional customer satisfaction through
                efficient project management, quality products and creative
                solutions.
              </p>
            </Col>
          </Row>
          <h1 className='mx-5 my-3 px-md-5 px-0'>Commitment</h1>
          <Row>
            <Col md={12}>
              <p
                className='mx-5 my-3 px-md-5 px-0'
                style={{
                  fontSize: 'calc(8px + 1vw)',
                  textAlign: 'justify',
                }}
              >
                Every aspect in the design work, we process and analyze in
                detail in order to create 1 idea / design work that can be a
                solution for you. We put the ideas and all these considerations
                into a 3D visual design to make it easier to understand the
                shape of the room/building they will use later.
              </p>
            </Col>
          </Row>
        </div>
      </Container>
      <div className='footer py-5'>
        <Container>
          <Row>
            <Col md={6} className='mt-5'>
              <small className='copyright'>Copyright - 2022 SQM Studio</small>
            </Col>
            <Col md={6}>
              <div className='text-md-end'>
                <h5>Follow Us At</h5>
              </div>
              <div className='sosmed text-md-end'>
                <a
                  href='https://www.instagram.com/sqm.studio/'
                  target='_blank'
                  rel='noreferrer'
                  style={{ margin: '0 1rem' }}
                >
                  <BsInstagram />
                </a>
                <a
                  href='https://www.behance.net/sqmstudio/'
                  target='_blank'
                  rel='noreferrer'
                  style={{ margin: '0 1rem' }}
                >
                  <FaBehanceSquare />
                </a>
              </div>
              <div className='contact text-md-end my-3'>
                For more information:{' '}
                <a href='/contact' target='_blank'>
                  Hubungi SQM
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='location py-5'>
        <Container>
          <Row>
            <Col md={12} className='mb-2'>
              <h5 className='location-title'>OUR LOCATIONS</h5>
            </Col>
            <Col md={2} sm={3} xs={3}>
              <Link to='/project#surabaya'>Surabaya</Link>
            </Col>
            <Col md={2} sm={3} xs={3}>
              <Link to='/project#solo'>Solo</Link>
            </Col>
            <Col md={2} sm={3} xs={3}>
              <Link to='/project#tangerang'>Tangerang</Link>
            </Col>
            <Col md={2} sm={3} xs={3}>
              <Link to='/project#semarang'>Semarang</Link>
            </Col>
            <Col md={2} sm={3} xs={3}>
              <Link to='/project#sidoarjo'>Sidoarjo</Link>
            </Col>
          </Row>
        </Container>
      </div>
      <FloatModal />
      <FloatWhatsapp />
    </Layout>
  );
}
