export const CarouselData = [
  {
    image: '/pictures/interior/mr-j-private-house-surabaya/private-house2.jpg',
  },
  {
    image: '/pictures/interior/mr-j-private-house-surabaya/private-house1.jpg',
  },
  {
    image: '/pictures/interior/mr-j-private-house-surabaya/private-house3.jpg',
  },
  {
    // image: '/pictures/interior/grand-harvest-surabaya/grand-harvest2.png'
    image: '/pictures/interior/mr-j-private-house-surabaya/picture6.jpg',
  },
];

export const carouselDataV2 = [
  {
    image: '/pictures/home/banner-01.jpg',
    title: 'Hi, Selamat Datang',
  },
  {
    image: '/pictures/interior/mr-j-private-house-surabaya/private-house1.jpg',
    title:
      'Kami menyediakan Solusi Kebutuhan Desain Interior & Ekseterior untuk Hunian / Tempat Usaha anda sepertiRumah, Apartment, Café, Kantor, Retail, dsb',
  },
  {
    image: '/pictures/home/banner-03.jpg',
    title:
      'Kami dapat membuat desain arsitektur & interior sesuai Dengan kebutuhan & budget anda',
  },
  {
    button: true,
    image: '/pictures/home/banner-04.jpg',
    title: 'So..Hubungi Kami Sekarang!!',
  },
];

export const RecentProject = [
  {
    image: '/pictures/interior/office-tangerang/office1.jpg',
    text: 'COMMERCIAL PROJECT',
    subtext: 'Tangerang / Office',
  },
  {
    image: '/pictures/interior/mr-a-private-house-surabaya/private-house1.jpg',
    text: 'RESIDENTIAL PROJECT',
    subtext: 'Surabaya / House',
  },
  {
    image: '/pictures/architecture/private-house-surabaya/private-house.jpg',
    text: 'RESIDENTIAL PROJECT',
    subtext: 'Surabaya / House',
  },
];
