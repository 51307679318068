import React from 'react';
import { Row, Container } from 'reactstrap';
import 'react-alice-carousel/lib/alice-carousel.css';
import './Project.scss';
import ProjectSidebar from '../components/Reusable/ProjectSidebar';
import ProjectCard from '../components/Reusable/ProjectCard';
import FloatWhatsapp from '../components/FloatWhatsapp';
import FloatModal from '../components/FloatModal';
import Layout from '../components/Layout';

const Project = () => {
  return (
    <Layout>
      <ProjectSidebar />

      <section className='project-content'>
        <Container className='pb-5'>
          <h4 className='project-content-title mt-5 mb-4' id='interior'>
            Interior
          </h4>
          <Row>
            <ProjectCard
              background='/pictures/interior/mr-t-private-house-surabaya/interior-photos/private-house1.jpg'
              id='surabaya'
              title='Private House'
              location='Design & Build (Surabaya)'
              year='2022'
              type='Interior'
              description='Japanese - Scandinavian was chosen as the design style for this house because we see that the area of   the house is not too big and the ceiling is not too high to be points that must be processed and designed in such a way that every room can display a broad and eye-catching impression. The interior of this house is dominated by only using 2 basic colors, namely brown wood and white. Characteristics of Scandinavian and Japanese design styles are applied in this house. We try to provide a lot of storage space that is closed in cabinets and drawers so that homeowners can keep their belongings neat and not look messy from the outside. So that each room will look neat with a combination of colors and lighting displayed. A few lines and black color will emphasize the pattern of space, but not excessive and only used as an accent. Ornamental plant knick-knacks are presented in the interior styling of this house so that it gives the impression of being one with the natural surroundings. The presence of white color also gives the impression of a wider and brighter room so that electricity consumption can be slightly reduced.'
              slideItems={[
                '/pictures/interior/mr-t-private-house-surabaya/interior-photos/private-house1.jpg',
                '/pictures/interior/mr-t-private-house-surabaya/interior-photos/private-house2.jpg',
                '/pictures/interior/mr-t-private-house-surabaya/interior-photos/private-house3.jpg',
                '/pictures/interior/mr-t-private-house-surabaya/interior-photos/private-house4.jpg',
                '/pictures/interior/mr-t-private-house-surabaya/interior-photos/private-house5.jpg',
                '/pictures/interior/mr-t-private-house-surabaya/interior-photos/private-house6.jpg',
                '/pictures/interior/mr-t-private-house-surabaya/interior-photos/private-house7.jpg',
                '/pictures/interior/mr-t-private-house-surabaya/interior-photos/private-house8.jpg',
              ]}
              progressPhotos={[
                '/pictures/interior/mr-t-private-house-surabaya/progress-photos/private-house-progress1.jpeg',
                '/pictures/interior/mr-t-private-house-surabaya/progress-photos/private-house-progress2.jpeg',
                '/pictures/interior/mr-t-private-house-surabaya/progress-photos/private-house-progress3.jpeg',
                '/pictures/interior/mr-t-private-house-surabaya/progress-photos/private-house-progress4.jpeg',
                '/pictures/interior/mr-t-private-house-surabaya/progress-photos/private-house-progress5.jpeg',
                '/pictures/interior/mr-t-private-house-surabaya/progress-photos/private-house-progress6.jpeg',
                '/pictures/interior/mr-t-private-house-surabaya/progress-photos/private-house-progress7.jpeg',
                '/pictures/interior/mr-t-private-house-surabaya/progress-photos/private-house-progress8.jpeg',
                '/pictures/interior/mr-t-private-house-surabaya/progress-photos/private-house-progress9.jpeg',
                '/pictures/interior/mr-t-private-house-surabaya/progress-photos/private-house-progress10.jpeg',
                '/pictures/interior/mr-t-private-house-surabaya/progress-photos/private-house-progress11.jpeg',
                '/pictures/interior/mr-t-private-house-surabaya/progress-photos/private-house-progress12.jpeg',
              ]}
              realPhotos={[
                '/pictures/interior/mr-t-private-house-surabaya/real-photos/private-house-real1.jpg',
                '/pictures/interior/mr-t-private-house-surabaya/real-photos/private-house-real2.jpg',
                '/pictures/interior/mr-t-private-house-surabaya/real-photos/private-house-real3.jpg',
                '/pictures/interior/mr-t-private-house-surabaya/real-photos/private-house-real4.jpg',
                '/pictures/interior/mr-t-private-house-surabaya/real-photos/private-house-real5.jpg',
                '/pictures/interior/mr-t-private-house-surabaya/real-photos/private-house-real6.jpg',
                '/pictures/interior/mr-t-private-house-surabaya/real-photos/private-house-real7.jpg',
                '/pictures/interior/mr-t-private-house-surabaya/real-photos/private-house-real8.jpg',
                '/pictures/interior/mr-t-private-house-surabaya/real-photos/private-house-real9.jpg',
                '/pictures/interior/mr-t-private-house-surabaya/real-photos/private-house-real10.jpg',
              ]}
            />
            <ProjectCard
              background='/pictures/interior/gunawangsa-tidar-apartment-surabaya/interior-photos/apartment1.jpg'
              title='Gunawangsa Tidar Apartment'
              id='surabaya'
              location='Design & Build (Surabaya)'
              year='2021'
              type='Interior'
              description='Industrial style was chosen as the design style for this apartment due to a request from the client. We  try  to  implement  the  requests  and  space  requirements  described  by  our  clients  in  an  interior  design  that  always  considers  the  function  and aesthetics of form. Lighting games with warm white light were chosen to add a warm impression to the room. We tried using industrial materials which are characteristic of an industrial design in the splashback kitchen with exposed red bricks and black iron which we implemented on cabinet handles and some other furniture. The dominant color is black and the combination of cement plaster & white walls gives the impression of a room that looks more industrial and blends more with natural conditions.'
              slideItems={[
                '/pictures/interior/gunawangsa-tidar-apartment-surabaya/interior-photos/apartment1.jpg',
                '/pictures/interior/gunawangsa-tidar-apartment-surabaya/interior-photos/apartment2.jpg',
                '/pictures/interior/gunawangsa-tidar-apartment-surabaya/interior-photos/apartment3.jpg',
                '/pictures/interior/gunawangsa-tidar-apartment-surabaya/interior-photos/apartment4.jpg',
                '/pictures/interior/gunawangsa-tidar-apartment-surabaya/interior-photos/apartment5.jpg',
              ]}
              progressPhotos={[
                '/pictures/interior/gunawangsa-tidar-apartment-surabaya/progress-photos/apartment-progress1.jpg',
                '/pictures/interior/gunawangsa-tidar-apartment-surabaya/progress-photos/apartment-progress2.jpg',
                '/pictures/interior/gunawangsa-tidar-apartment-surabaya/progress-photos/apartment-progress3.jpg',
                '/pictures/interior/gunawangsa-tidar-apartment-surabaya/progress-photos/apartment-progress4.jpg',
                '/pictures/interior/gunawangsa-tidar-apartment-surabaya/progress-photos/apartment-progress5.jpg',
                '/pictures/interior/gunawangsa-tidar-apartment-surabaya/progress-photos/apartment-progress6.jpg',
                '/pictures/interior/gunawangsa-tidar-apartment-surabaya/progress-photos/apartment-progress7.jpg',
              ]}
              realPhotos={[
                '/pictures/interior/gunawangsa-tidar-apartment-surabaya/real-photos/apartment-real1.jpeg',
                '/pictures/interior/gunawangsa-tidar-apartment-surabaya/real-photos/apartment-real2.jpeg',
                '/pictures/interior/gunawangsa-tidar-apartment-surabaya/real-photos/apartment-real3.jpeg',
                '/pictures/interior/gunawangsa-tidar-apartment-surabaya/real-photos/apartment-real4.jpeg',
                '/pictures/interior/gunawangsa-tidar-apartment-surabaya/real-photos/apartment-real5.jpeg',
              ]}
            />
            <ProjectCard
              background='/pictures/interior/office-tangerang/office1.jpg'
              title='Office Tangerang'
              id='tangerang'
              location='Proposal Design (Tangerang)'
              year='2022'
              type='Interior'
              description='A small lounge is designed with a modern luxury concept but still gives a tropical touch to its interior design. The use of marble motifs and rosegold colored accents are processed on several sides of the furniture and panels to give an elegant impression. the color of the wood and the selection of warm lights gives the impression and a comfortable touch when in the room.'
              slideItems={[
                '/pictures/interior/office-tangerang/office1.jpg',
                '/pictures/interior/office-tangerang/office2.jpg',
                '/pictures/interior/office-tangerang/office3.jpg',
                '/pictures/interior/office-tangerang/office4.jpg',
                '/pictures/interior/office-tangerang/office5.jpg',
              ]}
            />
            <ProjectCard
              background='/pictures/interior/grand-harvest-surabaya/grand-harvest1.png'
              id='surabaya'
              title='Grand Harvest'
              location='Proposal Design (Surabaya)'
              year='2021'
              type='Interior'
              description='The modern tropical concept is the main concept in this residential interior. The wood element as the wall panel and the marble pattern as an accent in the interior make the room feel warm and coupled with a warm white chandelier, the room feels comfortable when you are in it.'
              slideItems={[
                '/pictures/interior/grand-harvest-surabaya/grand-harvest1.png',
                '/pictures/interior/grand-harvest-surabaya/grand-harvest2.png',
              ]}
            />
            <ProjectCard
              background='/pictures/interior/mr-a-private-house-surabaya/private-house1.jpg'
              id='surabaya'
              title='Private House'
              location='Proposal Design (Surabaya)'
              year='2020'
              type='Interior'
              description='The living room design applies contemporary and modern style concepts which are combined to produce a warm impression of the room ambience with the dominant brown and gray colors in the room'
              slideItems={[
                '/pictures/interior/mr-a-private-house-surabaya/private-house1.jpg',
                '/pictures/interior/mr-a-private-house-surabaya/private-house2.jpg',
              ]}
            />
            <ProjectCard
              background='/pictures/interior/mr-j-private-house-surabaya/private-house1.jpg'
              id='surabaya'
              title='Private House'
              location='Proposal Design (Surabaya)'
              year='2020'
              type='Interior'
              description='The bedroom concept by applying tropical and modern styles produces a warmer atmosphere in the room, and uses wood texture material so that it looks cozier in the room.'
              slideItems={[
                '/pictures/interior/mr-j-private-house-surabaya/private-house1.jpg',
                '/pictures/interior/mr-j-private-house-surabaya/private-house2.jpg',
              ]}
            />
            <ProjectCard
              background='/pictures/interior/fipper-solo/interior-photos/fipper1.jpg'
              id='solo'
              title='Fipper Retail'
              location='Design & Build (Solo)'
              year='2022'
              type='Interior'
              description="One of the flip-flops brands whose names are familiar to the national ear has finally opened in the city of SOLO, Fipper Indonesia, which is located in Solo Paragon Mall, is one of the first retailers to stand in the city of Solo, Central Java. We try to present a modern and eye-catching design in the interior appearance. Based on the standards / design standards from SOP FIPPER INDONESIA, we are trying to process this store / retail design so that it  doesn't  seem  too  simple  and  monotonous.  We  play  lighting  and  ceiling  games  in  such  a  way  as  to  produce  the  impression  of  a  spacious  and attractive room. The Fipper logo with a synthetic grass backdrop and combined with a sandal-style ceiling and black on the corder walls gives a firm impression so that visitors' eyes will be fixed on the center part of the brand / retail logo."
              slideItems={[
                '/pictures/interior/fipper-solo/interior-photos/fipper1.jpg',
                '/pictures/interior/fipper-solo/interior-photos/fipper2.jpg',
                '/pictures/interior/fipper-solo/interior-photos/fipper3.jpg',
              ]}
              progressPhotos={[
                '/pictures/interior/fipper-solo/progress-photos/fipper-progress1.jpeg',
                '/pictures/interior/fipper-solo/progress-photos/fipper-progress2.jpeg',
                '/pictures/interior/fipper-solo/progress-photos/fipper-progress3.jpeg',
                '/pictures/interior/fipper-solo/progress-photos/fipper-progress4.jpeg',
                '/pictures/interior/fipper-solo/progress-photos/fipper-progress5.jpeg',
                '/pictures/interior/fipper-solo/progress-photos/fipper-progress6.jpeg',
                '/pictures/interior/fipper-solo/progress-photos/fipper-progress7.jpeg',
                '/pictures/interior/fipper-solo/progress-photos/fipper-progress8.jpeg',
              ]}
              realPhotos={[
                '/pictures/interior/fipper-solo/real-photos/fipper-real1.jpg',
                '/pictures/interior/fipper-solo/real-photos/fipper-real2.jpg',
                '/pictures/interior/fipper-solo/real-photos/fipper-real3.jpg',
                '/pictures/interior/fipper-solo/real-photos/fipper-real4.jpg',
                '/pictures/interior/fipper-solo/real-photos/fipper-real5.jpeg',
                '/pictures/interior/fipper-solo/real-photos/fipper-real6.jpeg',
                '/pictures/interior/fipper-solo/real-photos/fipper-real7.jpeg',
                '/pictures/interior/fipper-solo/real-photos/fipper-real8.jpeg',
              ]}
            />
            <ProjectCard
              background='/pictures/interior/sn-solo/SN HOUSE - 01.jpg'
              id='solo'
              title='SN HOUSE (Solo)'
              location='Design & Build (Solo)'
              year='2022'
              type='Interior'
              description='Located in the city of Solo, SN House is designed with a modern and minimalist tropical interior style. The architectural style also carries a modern minimalist  concept.  With  so  many  openings,  air  circulation  and  light  can  reduce  the  use  of  electricity  in  the  house.  The  efficiency  of  using  space without a partition allows the function of the room to be more flexible and look wider.The  material  color  tone  chosen  is  dominated  by  wood,  white,  cream,  gray,  and  a  few  gold  accents  to  give  the  interior  a  modern  and  luxurious impression. The black geometric lines also emphasize the shape or division of the room. The use of warm white lights also gives the impression of warmth in the house which is combined with the green plants around the yard.'
              slideItems={[
                '/pictures/interior/sn-solo/SN HOUSE - 01.jpg',
                '/pictures/interior/sn-solo/SN HOUSE - 02.jpg',
                '/pictures/interior/sn-solo/SN HOUSE - 03.jpg',
                '/pictures/interior/sn-solo/SN HOUSE - 04.jpg',
                '/pictures/interior/sn-solo/SN HOUSE - 05.jpg',
                '/pictures/interior/sn-solo/SN HOUSE - 06.jpg',
                '/pictures/interior/sn-solo/SN HOUSE - 07.jpg',
                '/pictures/interior/sn-solo/SN HOUSE - 08.jpg',
                '/pictures/interior/sn-solo/SN HOUSE - 09.jpg',
                '/pictures/interior/sn-solo/SN HOUSE - 10.jpg',
              ]}
            />
            <ProjectCard
              background='/pictures/interior/stand-booth-solo/MOTION SAND - 01.jpg'
              id='solo'
              title='Stand Booth (SOLO BARU)'
              location='Design & Build (Solo Baru 2022)'
              year='2022'
              type='Interior'
              description="Located in a shopping center in the city of Solo, a children's toy booth designed with an open space concept that is friendly for children to use when playing in it. Color play is the main variation and challenge when designing this booth, because the use of lots of colors and attractive to children is an important point."
              slideItems={[
                '/pictures/interior/stand-booth-solo/MOTION SAND - 01.jpg',
                '/pictures/interior/stand-booth-solo/MOTION SAND - 02.jpg',
                '/pictures/interior/stand-booth-solo/MOTION SAND - 03.jpg',
                '/pictures/interior/stand-booth-solo/MOTION SAND - 04.jpg',
              ]}
            />
            <ProjectCard
              background='/pictures/interior/an-bar-cafe/AN BAR & CAFE  - 01.jpg'
              id='solo'
              title='AN Bar & Café (Jakarta Barat)'
              location='Proposal Design (Jakbar 2022)'
              year='2022'
              type='Interior'
              description='This bar & cafe design with limited land and located in West Jakarta, carries a simple & colorful theme but still elegant. The playing of red, blue, white and warm white lights are the main and dominant colors that are highlighted because these colors are the colors that give the identity of a cafe/bar. Exposing industrial-style walls and paint materials add to the impression of a simple but friendly cafe for visitors. the outdoor area is also designed as simple as possible with a guardrail in the form of shrubs and lights and a glass partition / partition as a separator between the indoor and outdoor spaces.'
              slideItems={[
                '/pictures/interior/an-bar-cafe/AN BAR & CAFE  - 01.jpg',
                '/pictures/interior/an-bar-cafe/AN BAR & CAFE - 02.jpg',
              ]}
            />
          </Row>

          <h4 className='project-content-title mt-5 mb-4' id='architecture'>
            Architecture
          </h4>
          <Row>
            <ProjectCard
              background='/pictures/architecture/private-house-surabaya/private-house1.jpg'
              id='surabaya'
              title='Private House'
              location='Design & Build (Surabaya)'
              year='2022'
              type='Architecture'
              description='Standing on an area of   280 m2, the FA House design is made with a modern tropical building concept, where the use of wide and tall window openings on the façade contributes to efficiency in electricity use, cross ventilation occurs in the house so that air can flow throughout the rooms. , and give enough sunlight to the room during the day.As the center of the house, the swimming pool creates a balance between spaces, both in the living room, dining room, pantry, dirty kitchen, and service area. This connectivity between every area of   the house creates a unique experience.This house also seems spacious because this house is designed by minimizing partitions or barriers between rooms, where on an area of   280m2 the FA House has 4 ensuite rooms, a swimming pool, a very spacious family room and is directly integrated with the dining room, kitchen and foyer. Every corner of the building has a clear and simple geometric shape and aesthetic.'
              slideItems={[
                '/pictures/architecture/private-house-surabaya/private-house1.jpg',
                '/pictures/architecture/private-house-surabaya/private-house2.jpg',
                '/pictures/architecture/private-house-surabaya/private-house3.jpg',
                '/pictures/architecture/private-house-surabaya/private-house4.jpg',
                '/pictures/architecture/private-house-surabaya/private-house5.jpg',
                '/pictures/architecture/private-house-surabaya/private-house6.jpg',
                '/pictures/architecture/private-house-surabaya/private-house7.jpg',
                '/pictures/architecture/private-house-surabaya/private-house8.jpg',
              ]}
            />
            <ProjectCard
              background='/pictures/architecture/private-house-sidoarjo/private-house1.jpg'
              id='sidoarjo'
              title='O House (Private House)'
              location='Proposal Design | Architecture (2021)'
              year='2022'
              type='Architecture'
              description='Industrial - tropical architectural style, a house in a residential area in the Sidoarjo area called "O House" is designed to maximize wide and tall openings but uses secondary skin as a heat reduction sun, because the orientation of this building faces west. So that during the day sunlight is exposed to quite a lot on the facade of the building. With the secondary skin from wooden windows and rosters and plant barriers, the sunlight that enters during the day can be reduced properly so that the room in the house does not feel hot. The window openings are made of cross ventilation so that air can flow in from the front and be expelled from the rear window of the house. The use of vegetation around the house is also able to cool the area in the house and filter the air that enters the house.On the 1st floor of this house, it consists of a foyer and guest area, a living area that is directly connected without a partition with a pantry and dining area, a kitchen area behind the carport, a service area at the rear and continuous with the rear garden and also the powderroom. a room without a partition provides a solution for making home designs built on limited land. while on the 2nd floor there are 5 bedrooms that directly face the road and there is a prayer room. This house is designed to maximize the function of space on limited land but still pay attention to the elements of good lighting and air circulation. dont forget also to open space or green areas made around the house so that water and air infiltration that enters the house can be filtered through vegetation properly'
              slideItems={[
                '/pictures/architecture/private-house-sidoarjo/private-house1.jpg',
                '/pictures/architecture/private-house-sidoarjo/private-house2.jpg',
                '/pictures/architecture/private-house-sidoarjo/private-house3.jpg',
                '/pictures/architecture/private-house-sidoarjo/private-house4.jpg',
              ]}
            />
            <ProjectCard
              background='/pictures/architecture/setetes-cafe-semarang/cafe1.jpg'
              id='semarang'
              title='Setetes Café (Semarang)'
              location='Proposal Design | Architecture (2022)'
              year='2022'
              type='Architecture'
              description='This industrial tropical themed cafe is located in Semarang. With around 400m2 of land, this cafe is dominated by exposed brick and exposed steel materials. The industrial concept was chosen because apart from being able to cut the construction budget, this cafe actually has a unique façade and shape. Open spaces with semi-outdoor dining areas are designed without partitions and are directly adjacent to the surrounding vegetation. At the back of this cafe there is a dining area made of steps and in the middle there is a small performance stage so that visitors can enjoy performances by the cafe owner while relaxing eating ordered dishes in an aesthetic, comfortable, simple and romantic atmosphere.'
              slideItems={[
                '/pictures/architecture/setetes-cafe-semarang/cafe1.jpg',
                '/pictures/architecture/setetes-cafe-semarang/cafe2.jpg',
              ]}
            />
          </Row>
        </Container>
      </section>

      <FloatWhatsapp />
      <FloatModal />
    </Layout>
  );
};

export default Project;
