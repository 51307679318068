import React, { useState, useEffect } from 'react';
import './Carousel.scss';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import TextStyle from './TextStyle';

const Carousel = (props) => {
  const [current, setCurrent] = useState(0);
  const length = props.slides.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  useEffect(() => {
    if (props.autoSlide) {
      const interval = setInterval(() => {
        setCurrent(current === 0 ? length - 1 : current - 1);
      }, 3000);
      return () => {
        clearInterval(interval);
      };
    }
  });

  if (!Array.isArray(props.slides) || props.slides.length <= 0) {
    return null;
  }

  return (
    <>
      <section className='slider'>
        {props.direction && (
          <>
            <BsChevronLeft className='left-arrow' onClick={prevSlide} />
            <BsChevronRight className='right-arrow' onClick={nextSlide} />
          </>
        )}
        {props.slides.map((slide, index) => {
          return (
            <div key={index}>
              <div className={`slide ${index === current && 'active'}`}>
                {index === current && (
                  <img
                    src={slide.image}
                    alt={slide.image}
                    key={index + slide.image}
                    className='image'
                  />
                )}
              </div>
              {index === current && slide.text && (
                <div className='caption'>
                  {props.count && (
                    <div className='count'>
                      <span className='count-current'>
                        <TextStyle text={current + 1} type='text-focus-in' />
                      </span>
                      <span className='count-line'>
                        <TextStyle text='/' type='text-focus-in' />
                      </span>
                      <span className='count-total'>
                        <TextStyle text={length} type='text-focus-in' />
                      </span>
                    </div>
                  )}
                  <p>
                    <TextStyle text={slide.text} type='text-focus-in' />
                  </p>
                  <small style={{ color: 'var(--primary-color)' }}>
                    <TextStyle text={slide.subtext} type='text-focus-in' />
                  </small>
                </div>
              )}
            </div>
          );
        })}
      </section>
    </>
  );
};

export default Carousel;
