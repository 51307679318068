import React from "react";
import "./FloatWhatsapp.scss";
import { BsWhatsapp } from "react-icons/bs";

const FloatWhatsapp = () => {

    return (
        <div className="float-whatsapp">
            <a href="https://api.whatsapp.com/send?phone=6282361008195&text=Hei%20SQM%20Studio.%20Can%20I%20ask%20you%20about%20architecture%20/%20interior%20design?" target="_blank" rel="noreferrer">
                <BsWhatsapp />
            </a>
        </div>
    )
}

export default FloatWhatsapp