import React from 'react';
import {
  NavLink as RRNavLink,
  Link,
  useNavigate,
  Route,
  useLocation,
} from 'react-router-dom';
import {
  Row,
  Col,
  Container,
  Button,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import './Homepage.scss';

export default function Header() {
  const pathName = useLocation();
  return (
    <Container
      fluid
      className='header px-md-5'
      style={{
        background: pathName.pathname === '/' ? 'transparent' : '#3c3634',
      }}
    >
      <Navbar dark expand='lg' style={{ backgroundColor: 'transparent' }}>
        <NavbarBrand href='/' className='sqm-logo' style={{ margin: '0' }}>
          <img src='/pictures/logo-2.png' alt='logo' />
        </NavbarBrand>
        <Nav
          className='flex-md align-items-center header__navbar justify-content-end'
          navbar
        >
          <NavItem className='mx-2'>
            <NavLink to='/project' tag={RRNavLink}>
              <span className='regular-link'>Projects</span>
            </NavLink>
          </NavItem>
          <NavItem className='mx-2'>
            <NavLink to='/about' tag={RRNavLink}>
              <span className='regular-link'>About Us</span>
            </NavLink>
          </NavItem>
          <NavItem className='mx-2'>
            <NavLink to='' tag={RRNavLink}>
              <span className='regular-link disabled'>Career</span>
            </NavLink>
          </NavItem>
          <NavItem className='mx-2'>
            <NavLink to='/contact' tag={RRNavLink}>
              <Button className='regular-link'>Contact Us</Button>
            </NavLink>
          </NavItem>
        </Nav>
      </Navbar>
    </Container>
  );
}
